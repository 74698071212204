"use client";

import { FC } from "react";

import { useAppModalsContext } from "@app/auth/index";
import { MuiButton } from "@causevest/ui-kit";
import { ButtonProps } from "@mui/material";

export const LogInBtn: FC<ButtonProps> = (props) => {
  const { setLogInOpen } = useAppModalsContext();

  return (
    <MuiButton
      {...props}
      disableRipple
      variant="text"
      onClick={setLogInOpen}
      sx={{
        padding: 0,
        fontWeight: "500 !important",
        minWidth: "auto",
        marginTop: "-2px !important",
        ["&:hover"]: { background: "transparent" },
      }}
    >
      Log In
    </MuiButton>
  );
};
