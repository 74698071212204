import React, { FC } from "react";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";

import { PasswordsForm } from "@app/auth";
import { MuiDialog } from "@causevest/ui-kit";

import { useSession } from "@contexts";

import { SignUpValues } from "@lib/types/common";

interface Props {
  rawValues?: Partial<SignUpValues>;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateNewUserModal: FC<Props> = ({ isOpen, onClose, rawValues }) => {
  const { register } = useSession();
  const onSubmit = async ({ password }: FieldValues) => {
    if (rawValues) {
      await register({ ...rawValues, password });
    } else {
      toast.error("Enter Email and Display Name first", {
        theme: "colored",
      });
    }
  };

  return (
    <MuiDialog title="Create Password" isOpen={isOpen} handleClose={onClose}>
      <PasswordsForm onSubmit={onSubmit} onClose={onClose} withInfo />
    </MuiDialog>
  );
};
