"use client";

import React, { FC, ReactElement, createContext, useCallback, useContext, useState } from "react";
import { FieldValues } from "react-hook-form";

import { CreateNewUserModal, LogInModal, ResetPasswordModal, SignUpModal } from "@app/auth";
import { useElementOpen } from "@causevest/ui-kit";
import noop from "lodash.noop";

import { SignUpValues } from "@lib/types";

interface ModalsState {
  setSignUpOpen: () => void;
  setSubmitPasswordOpen: () => void;
  setResetPasswordOpen: () => void;
  setLogInOpen: () => void;
}

export const AppModalsContext = createContext<ModalsState>({
  setSignUpOpen: noop,
  setSubmitPasswordOpen: noop,
  setResetPasswordOpen: noop,
  setLogInOpen: noop,
});

export const useAppModalsContext = (): ModalsState => useContext(AppModalsContext);

export const AppModalsProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const { isOpen: isLogInOpen, open: setLogInOpen, close: closeLogIn } = useElementOpen(false);
  const { isOpen: isSignUpOpen, open: setSignUpOpen, close: closeSignUp } = useElementOpen(false);
  const {
    isOpen: isSubmitPasswordOpen,
    open: setSubmitPasswordOpen,
    close: closeSubmitPassword,
  } = useElementOpen(false);
  const {
    isOpen: isResetPasswordOpen,
    open: setResetPasswordOpen,
    close: closeResetPassword,
  } = useElementOpen(false);
  const [rawValues, setRawValues] = useState<Partial<SignUpValues>>();

  const onEmailSubmit = useCallback(
    (values: FieldValues) => {
      setRawValues(values);
      closeSignUp();
      setSubmitPasswordOpen();
    },
    [closeSignUp, setSubmitPasswordOpen],
  );

  const onResetPasswordCall = useCallback(() => {
    closeLogIn();
    setResetPasswordOpen();
  }, [closeLogIn, setResetPasswordOpen]);

  return (
    <AppModalsContext.Provider
      value={{
        setSignUpOpen,
        setSubmitPasswordOpen,
        setResetPasswordOpen,
        setLogInOpen,
      }}
    >
      {children}
      <SignUpModal isOpen={isSignUpOpen} onClose={closeSignUp} onSubmit={onEmailSubmit} />
      <CreateNewUserModal
        isOpen={isSubmitPasswordOpen}
        onClose={closeSubmitPassword}
        rawValues={rawValues}
      />
      <LogInModal
        isOpen={isLogInOpen}
        onClose={closeLogIn}
        onSignUp={setSignUpOpen}
        onResetPassword={onResetPasswordCall}
      />
      <ResetPasswordModal isOpen={isResetPasswordOpen} onClose={closeResetPassword} />
    </AppModalsContext.Provider>
  );
};
